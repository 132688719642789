import { get, post } from "../common/axiosHttp.js";

// 地图站点列表
export const getGuardList = (params) => get("/entranceGuard/page", params);

export const guardAdd = (params) =>
  post("/entranceGuard/addEntranceGuard", params);

  export const guardEdit = (params) =>
  post("/entranceGuard/updateEntranceGuard", params);

