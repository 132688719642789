<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input v-model="value" placeholder="请输入设备名称"></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="userAdd">新增设备</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="deviceAddress" label="设备地址码">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
        <el-table-column prop="deviceType" label="设备类型"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrent"
      >
      </el-pagination>
    </div>
    <!-- 新增设备 -->
    <el-dialog
      class="border-r-8"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      @close="resetForm('ruleForm')"
    >
      <div slot="title" class="dialogAdd t-x font-s-18">
        {{ title }}
      </div>
      <div class="contentAdd">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          hide-required-asterisk
        >
          <el-form-item
            label="设备地址码"
            prop="deviceAddress"
            style="height:calc(35/1080*100vw)"
          >
            <el-input
              v-model="ruleForm.deviceAddress"
              maxlength="8"
              placeholder="请输入设备地址码"
              :readonly="title == '门禁红外设备-编辑' ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="设备名称"
            prop="deviceName"
            style="height:calc(35/1080*100vw)"
            maxlength="11"
          >
            <el-input
              v-model="ruleForm.deviceName"
              placeholder="请输入设备名称"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="设备类型"
            prop="deviceType"
            style="height:calc(35/1080*100vw)"
          >
            <el-select
              placeholder="请选择设备类型"
              v-model="ruleForm.deviceType"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')" class="guanBi"
          >关闭</el-button
        >
        <el-button
          v-if="title == '门禁红外设备-新增'"
          type="primary"
          style="margin-left:calc(30/1920*100vw)"
          @click="handleAdd"
          >保存</el-button
        >
        <el-button v-else type="primary" @click="userEnit">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { guardAdd, guardEdit } from "../../../api/infrared";
import { guardFall } from "./validata";
var isGuardFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入设备地址码"));
  } else if (!guardFall(value)) {
    callback(new Error("请输入正确的设备地址码"));
  } else {
    callback();
  }
};

export default {
  components: {},
  data() {
    return {
      tableData: [], //用户列表
      title: "",
      page: 1,
      size: 10,
      total: 0, //总条数
      addShow: false,
      ruleForm: {
        deviceAddress: "", //设备地址码
        deviceName: "", //设备名称
        deviceType: "" // 设备类型
      },
      rules: {
        deviceAddress: [
          { required: true, message: "请输入设备地址码", trigger: "change" },
          {
            required: true,
            message: "请输入正确的设备地址码",
            trigger: "change",
            validator: isGuardFall
          }
        ],
        deviceName: [
          { required: true, message: "请输入设备名称", trigger: "input" }
        ],
        deviceType: [
          { required: true, message: "请输入设备类型", trigger: "input" }
        ]
      },
      options: [
        {
          value: 0,
          label: "门禁"
        },
        {
          value: 1,
          label: "红外线"
        }
      ], //角色下拉框
      departList: [], // 部门下拉框
      value: "",
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 新增
    userAdd() {
      this.ruleForm = {};
      this.addShow = true;
      this.title = "门禁红外设备-新增";
    },
    handleAdd() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          guardAdd({
            deviceAddress: this.ruleForm.deviceAddress,
            deviceName: this.ruleForm.deviceName,
            deviceType: this.ruleForm.deviceType
          })
            .then(res => {
              this.$message.success(res.message);
              this.addShow = false;
              this.getGuardList();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        }
      });
    },
    // 编辑
    handleEdit(item) {
      this.ruleForm = {};
      // this.getUser();
      this.addShow = true;
      this.title = "门禁红外设备-编辑";
      // 深拷贝
      this.ruleForm = JSON.parse(JSON.stringify(item));
      console.log(this.ruleForm);
    },
    // 编辑保存
    userEnit() {
      if (this.ruleForm.deviceType == "门禁") {
        this.$set(this.ruleForm, "deviceType", 0);
      }
      if (this.ruleForm.deviceType == "红外线") {
        this.$set(this.ruleForm, "deviceType", 1);
      }
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          guardEdit({
            deviceName: this.ruleForm.deviceName,
            id: this.ruleForm.id,
            deviceType: this.ruleForm.deviceType
          })
            .then(res => {
              this.$message.success(res.message);
              this.addShow = false;
              this.getGuardList();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        }
      });
    },
    // 删除
    handleDelete(item) {
      let { id } = item;
      this.$confirm("此操作将永久删除该设备名称，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post(`/entranceGuard/delEntranceGuard`, {
            id: id
          }).then(res => {
            // console.log(res);
            if (res.code == 200) {
              this.$message({
                message: res.message,
                type: "success"
              });
              this.size = 10;
              if (this.total % 10 == 1) {
                this.page--;
              }
              this.getGuardList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 获取用户列表
    getGuardList() {
      this.loading = true;
      this.$get(`/entranceGuard/page?page=${this.page}&size=${this.size}`).then(
        res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.tableData.forEach(item => {
            if (item.deviceType == 0) {
              item.deviceType = "门禁";
            } else {
              item.deviceType = "红外线";
            }
          });
          this.loading = false;
        }
      );
    },

    //取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addShow = false;
    },
    // 分页切换
    handleCurrent(page) {
      this.page = page;
      this.getGuardList();
    },
    // 查询
    handleSearch() {
      this.ruleForm.deviceName = this.value;
      this.loading = true;
      this.$get(`/entranceGuard/page?page=${this.page}&size=${this.size}`, {
        deviceName: this.ruleForm.deviceName
      }).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.tableData.forEach(item => {
          if (item.deviceType == 0) {
            item.deviceType = "门禁";
          } else {
            item.deviceType = "红外线";
          }
        });
        this.loading = false;
      });
    },
    handleReset() {
      this.value = "";
      this.getGuardList();
    }
  },
  created() {
    this.getGuardList();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.dialogAdd {
  color: #1d1d1d;
  font-weight: bold;
}
.contentAdd {
  .el-form {
    margin-top: calc(25 / 1080 * 100vh);
    .el-form-item {
      margin-bottom: calc(10 / 1080 * 100vh);
    }
    ::v-deep .el-form-item__label {
      color: #666;
      text-align: right;
      width: calc(100 / 1920 * 100vw);
      margin-right: calc(10 / 1920 * 100vw);
    }
  }
  .el-input {
    width: calc(250 / 1920 * 100vw);
  }
  ::v-deep .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
  .el-select {
    width: calc(250 / 1920 * 100vw);
  }
}
::v-deep .el-dialog {
  box-sizing: border-box;
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
}
::v-deep .el-dialog__footer {
  padding-right: calc(35 / 1920 * 100vw);
}
::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}

::v-deep .menu_left {
  .el-input__inner {
    width: calc(200 / 1920 * 100vw);
  }
}
::v-deep .el-form-item__error {
  position: absolute;
  top: calc(48 / 1080 * 100vh);
  left: calc(125 / 1920 * 100vw) !important;
}
::v-deep .el-icon-circle-check:before {
  content: "" !important;
}
</style>
